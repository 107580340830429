@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after,
.gm-style .gm-style-iw-tc::after {
  @apply bg-background;
}

.gm-style .gm-style-iw .gm-ui-hover-effect {
  right: 0 !important;
  top: 0 !important;
}

.gm-style .gm-style-iw .gm-ui-hover-effect > span {
  @apply bg-white;
}

/* React Date picker styles */
.react-datepicker {
  @apply !bg-card-background-dark;
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker__header {
  @apply !bg-card-background-dark;
  border-bottom: unset !important;
}
.react-datepicker__current-month {
  color: #fff !important;
}
.react-datepicker {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  box-shadow:
    0px 0.664px 2.214px 0px rgba(0, 0, 0, 0.17),
    0px 1.596px 5.32px 0px rgba(0, 0, 0, 0.24),
    0px 3.005px 10.017px 0px rgba(0, 0, 0, 0.3),
    0px 5.361px 17.869px 0px rgba(0, 0, 0, 0.36),
    0px 10.027px 33.422px 0px rgba(0, 0, 0, 0.43),
    0px 24px 80px 0px rgba(0, 0, 0, 0.6);
}
.react-datepicker__day-name {
  color: #797b86 !important;
}
.react-datepicker__day {
  color: #abb0bd !important;
}
.react-datepicker__day:hover {
  @apply !bg-card-background-light;
}
.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}
.react-datepicker__day--selected {
  @apply !bg-card-background-light !font-semibold !text-primary;
}
.react-datepicker__day--disabled {
  color: #45454a !important;
  background-color: transparent;
  cursor: not-allowed;
}

.Toastify__toast--success {
  border-radius: 16px !important;
  border: 1px solid #7adc89;
  background: linear-gradient(
      90deg,
      rgba(122, 220, 137, 0.15) 0%,
      rgba(122, 220, 137, 0) 100%
    ),
    #121214 !important;
  box-shadow: 0px 8px 34px 0px rgba(0, 0, 0, 0.35) !important;
  padding: 16px 24px !important;
}
.Toastify__toast--success svg {
  fill: #7adc89 !important;
}

.Toastify__toast-container {
  width: fit-content !important;
}

button.Toastify__close-button.Toastify__close-button--light {
  align-self: center !important;
  margin-left: 25px;
  opacity: 1 !important;
}
button.Toastify__close-button.Toastify__close-button--light svg {
  fill: #898c90 !important;
}
.success {
  margin-left: 12px;
}

.Toastify__toast--error {
  border-radius: 16px;
  border: 1px solid #e49797;
  background: linear-gradient(
      90deg,
      rgba(228, 151, 151, 0.15) 0%,
      rgba(228, 151, 151, 0) 100%
    ),
    #121214 !important;
  box-shadow: 0px 8px 34px 0px rgba(0, 0, 0, 0.35) !important;
}
.Toastify__toast--error svg {
  fill: #e49797 !important;
}
.Toastify__toast--error .gethelp {
  border: 1px solid #898c90;
}

.travel-history .gm-style-iw.gm-style-iw-c {
  @apply overflow-auto rounded-none bg-transparent;
  padding: 0;
}
.travel-history .gm-style .gm-style-iw .gm-ui-hover-effect {
  right: 10px !important;
}

.travel-history .slick-prev:before,
.travel-history .slick-next:before {
  opacity: 1 !important;
}

.travel-history .slick-prev {
  left: 8px !important;
  z-index: 1 !important;
}
.travel-history .slick-next {
  right: 8px !important;
  z-index: 1 !important;
}
.travel-history ::-webkit-scrollbar {
  display: none;
}

.navactive {
  display: block !important;
  position: absolute;
  width: 100%;
  top: 80px;
  background: linear-gradient(
      90deg,
      rgba(175, 198, 212, 0.15) 0.26%,
      rgba(175, 198, 212, 0) 66.14%
    ),
    #0b0b0d;
  left: 0;
  right: 0;
  padding: 10px 20px;
  border: 1px solid #3d3f43;
}
.navactive a {
  display: block;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .travel-history .gm-style-iw-c {
    width: 320px !important;
    min-width: 320px !important;
  }
}

@keyframes slideRight {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
@layer components {
  /* General */
  .bg-ellipse {
    border-radius: var(--radius-none, 679px);
    opacity: 0.5;
    background: theme("backgroundColor.background-radial-gradient");
    filter: blur(243.66029357910156px);
  }
  /* Cards */
  .card-linear-gradient-primary-bright {
    border-radius: 8px;
    background: theme("colors.card.linear-gradient.primary-bright");
  }
  .card-linear-gradient-primary-inverted {
    background: theme("colors.card.linear-gradient.primary-inverted");
  }
  .card-linear-gradient-default {
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: theme("colors.card.linear-gradient.default");
  }
  .card-linear-gradient-default-2 {
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: theme("colors.card.linear-gradient.default-2");
  }
  .card-linear-gradient-bronze {
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: theme("colors.card.linear-gradient.bronze");
  }
  .card-linear-gradient-silver {
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: theme("colors.card.linear-gradient.silver");
  }
  .card-linear-gradient-gold {
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: theme("colors.card.linear-gradient.gold");
  }
  .card-linear-gradient-gold-bright {
    border-radius: 8px;
    border: 1px solid theme("colors.card.border-gold");
    background: theme("colors.card.linear-gradient.gold-bright");
  }
  .card-linear-gradient-titanium {
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: theme("colors.card.linear-gradient.titanium");
  }
  .card-linear-gradient-platinum {
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: theme("colors.card.linear-gradient.platinum");
  }
  /* Loyalty tag */
  .loyalty-tag-default {
    background: theme("colors.loyalty-tag.default");
  }
  .loyalty-tag-bronze {
    background: theme("colors.loyalty-tag.bronze");
  }
  .loyalty-tag-silver {
    background: theme("colors.loyalty-tag.silver");
  }
  .loyalty-tag-gold {
    background: theme("colors.loyalty-tag.gold");
  }
  .loyalty-tag-titanium {
    background: theme("colors.loyalty-tag.titanium");
  }
  .loyalty-tag-platinum {
    background: theme("colors.loyalty-tag.platinum");
  }
  /* Text */
  .text-linear-gradient-default {
    @apply bg-gradient-to-b from-white to-primary-gold bg-clip-text text-transparent;
  }
  .text-linear-gradient-default-inverted {
    @apply bg-gradient-to-b from-primary-gold to-white bg-clip-text text-transparent;
  }
  /* Button */
  .pill-button {
    background: theme("colors.button.pill");
    color: theme("colors.button.pill-text");
    border: 1px solid theme("colors.button.pill-border");
  }
  .pill-button-selected {
    background: theme("colors.button.pill-selected");
    color: theme("colors.button.pill-selected-text");
    border: 1px solid theme("colors.button.pill-border");
  }
  .pill-2-button {
    background: theme("colors.button.pill-2");
    color: theme("colors.button.pill-2-text");
    border: 1px solid theme("colors.button.pill-2-border");
  }
  .pill-2-button-selected {
    background: theme("colors.button.pill-2-selected");
    color: theme("colors.button.pill-2-selected-text");
    border: 1px solid theme("colors.button.pill-2-selected");
  }
  /* Backgrounds */
  .bg-global-background-transparent {
    background-image: url(/assets/white-map-background-transparent.svg);
    background-position: center;
    background-size: 100vw 100vh;
    background-repeat: no-repeat;
  }
  .bg-global-background {
    background-image: url(/assets/white-map-background.svg);
    background-position: center;
    background-size: 100vw 100vh;
    background-repeat: no-repeat;
  }
  .bg-global-gold-background {
    background-image: url(/assets/gold-map-background.svg);
    background-position: center;
    background-size: 100vw 100vh;
    background-repeat: no-repeat;
  }
  /* Map Marker */
  .marker-shadow {
    box-shadow:
      0 2px 4px rgba(0, 0, 0, 0.18),
      0px 2px 4px 0px rgba(0, 0, 0, 0.18),
      0px 0px 0px 1px rgba(0, 0, 0, 0.08);
  }
}

@keyframes progressAnimation {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.user-select-none {
  user-select: none;
  webkit-user-select: none;
  moz-user-select: none;
  ms-user-select: none;
}
